import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_3/sub_3/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-3-3-1"}}) {
      body
    }
    before: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-3-3-1-before"}}) {
      body
    },
    after: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-3-3-1-after"}}) {
        body
    },
  }
  `);
  return (
    <Template query={query} title="Часть II" />
  );
};


export default Slide;
